import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { PanelMenu } from 'primereact/panelmenu';
import { Menubar } from 'primereact/menubar';
import ThemeSwitcher from './ThemeSwitcher';

const Header = () => {
    const [visible, setVisible] = useState(false);

    const items = [
        // {
        //     label: 'Home',
        //     command: () => window.location.href = '/',
        // },
        // {
        //     label: 'About Us',
        //     command: () => window.location.href = '/about',
        // },


        // {
        //     label: 'Careers',
        //     command: () => window.location.href = '/careers',
        // },
        // {
        //     label: 'Contact Us',
        //     command: () => window.location.href = '/contact',
        // },
    ];

    const start = (
        <a
            className=" header-logo h-8 w-auto" // Adjust the height and width as needed
        />
    );


    //     const buttonStyle = `
    //     text-transparent bg-clip-text bg-gradient-to-r 
    //     from-cyan-400 via-cyan-500 to-cyan-600 
    //     hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
    //     focus:ring-cyan-300 dark:focus:ring-cyan-800 
    //     font-bold rounded-lg text-sm px-5 py-2.5 
    //     text-center me-2 mb-2
    // `;

    //     const signupButtonStyle = `
    //     text-transparent bg-clip-text bg-gradient-to-r 
    //     from-teal-400 via-teal-500 to-teal-600 
    //     hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
    //     focus:ring-teal-300 dark:focus:ring-teal-800 
    //     font-bold rounded-lg text-sm px-5 py-2.5 
    //     text-center me-2 mb-2
    // `;



    const end = (
        <div className="flex items-center gap-2">
            {/* <div className="hidden lg:flex items-center gap-2">
                <button
                    type="button"
                    className={buttonStyle}
                    onClick={() => window.location.href = '/login'}
                >
                    Login
                </button>
                <button
                    type="button"
                    className={signupButtonStyle}
                    onClick={() => window.location.href = '/signup'}
                >
                    Sign Up
                </button>

            </div> */}
            {/* <a href="https://github.com" className=" text-gray-800 dark:text-white mr-2">
                <i className="pi pi-github"></i>
            </a> */}

            <ThemeSwitcher />
        </div>
    );

    return (
        <header className="sticky top-0 z-50 w-full bg-white dark:bg-slate-950 text-slate-900 dark:text-white px-6 py-4 md:px-8 md:py-6 lg:px-12 lg:py-8">
            <div className="flex items-center justify-between">
                {/* Left side */}
                <div className="flex items-center">
                    {start}
                </div>

                {/* Menu Items for large screens */}
                <div className="hidden lg:flex flex-grow justify-end">
                    <nav className="mt-2 border-none bg-transparent dark:bg-slate-950 text-sm transform translate-x-2 
    text-transparent bg-clip-text bg-gradient-to-r 
    from-teal-400 via-teal-500 to-teal-600 
    hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
    focus:ring-teal-300 dark:focus:ring-teal-800 font-bold
    rounded-lg px-5 py-2.5 text-center me-2 mb-2 ">

                        {/* Map through your items to render them as links or buttons */}
                        {items.map((item, index) => (
                            <a
                                key={index}
                                href={item.url}
                                className="px-4 py-2 bg-clip-text 
            text-slate-900 dark:text-white 
            hover:text-purple-700"
                            >
                                {item.label}
                            </a>
                        ))}
                    </nav>

                </div>

                <div className="flex items-center gap-2">
                    {/* <div className="lg:hidden">
                        <Button
                            icon={<i className="pi pi-bars text-gray-500 dark:text-white"></i>} // Set the icon color here
                            onClick={() => setVisible(true)}
                            className="bg-transparent border-0 hover:bg-transparent bg-white dark:bg-slate-950"
                        />
                    </div> */}




                    {end}
                </div>




            </div>

            {/* Sidebar for small and medium screens */}
            <Sidebar visible={visible} onHide={() => setVisible(false)} baseZIndex={1000} className='dark:bg-slate-950 border-none'>
                <PanelMenu model={items} className="w-full md:w-80 lg:w-96" />
                <div className="p-4">
                    <h3 className="text-lg font-semibold">Contact Us</h3>
                    <p className="mt-2 text-sky-700">Address: Bangalore, India-560034
                        <br /> Hyderabad, India-500072
                    </p>
                    <p className='text-sky-700'>Email: <a href="mailto:info@streakify.in" className="text-blue-600 hover:underline">info@streakify.in</a></p>
                    <p className='text-sky-700'>Phone: +91-8105827558</p>
                    <div className="mt-4 flex space-x-3">
                        <a href="https://twitter.com" className="text-blue-500 hover:text-blue-700" aria-label="Twitter">
                            <i className="pi pi-twitter text-xl text-sky-700"></i>
                        </a>
                        <a href="https://linkedin.com" className="text-blue-700 hover:text-blue-900" aria-label="LinkedIn">
                            <i className="pi pi-linkedin text-xl text-sky-700"></i>
                        </a>
                        <a href="https://facebook.com" className="text-blue-600 hover:text-blue-800" aria-label="Facebook">
                            <i className="pi pi-facebook text-xl text-sky-700"></i>
                        </a>
                        <a href="https://github.com" className="text-blue-600 hover:text-blue-800" aria-label="GitHub">
                            <i className="pi pi-github text-xl text-sky-700"></i>
                        </a>
                    </div>
                </div>
            </Sidebar>
        </header>

    );
};

export default Header;
